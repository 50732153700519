import React, { useEffect } from 'react';
import SelectList from 'components/select-list';
import { useFilters } from 'registration/queries/hooks.js';
import Loader from 'components/Loader';
import { useParams } from 'react-router-dom';
import { useUserProfile } from 'profiles/queries/profileHooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

export default function FiltersSection({
  setGender,
  ageCategory,
  setAgeCategory,
  sport,
  setSport,
  sportCategory,
  setSportCategory,
  setWeightCategory,
  weightCategory,
  filterStatusHandler,
}) {
  const params = useParams();
  const { data: profile } = useUserProfile();

  const filtersQueryData = useFilters({
    tournament_id: params.tournament_id,
    sport: sport === 'All' ? '' : sport,
    age_category: ageCategory === 'All' ? '' : ageCategory,
    sport_category: sportCategory === 'All' ? '' : sportCategory,
    weight_category: weightCategory === 'All' ? '' : weightCategory,
  });

  const resetFilter = () => {
    setGender('All');
    setAgeCategory('All');
    setSport('All');
    setSportCategory('All');
    setWeightCategory('All');
    filterStatusHandler();
  };

  useEffect(() => {
    filtersQueryData.refetch();
  }, [sport, ageCategory, sportCategory, weightCategory]);

  useEffect(() => {
    const ages = filtersQueryData?.data?.age_category.map((el) => el.split('-')[1]);
    ages && ages.sort();
    console.log('age', ages, ageCategory, ageCategory.length);
    ages && ages[0] && profile.message.primary_role === 'athlete' && ageCategory.length === 0
      ? setAgeCategory(ages && `U-${ages[0]}`)
      : setAgeCategory(ageCategory);
  }, [filtersQueryData?.data]);

  if (filtersQueryData.isLoading) return null;
  return (
    <div className="md:mr-6 border border-gray-200 rounded-3xl shadow-footerPopUp">
      <div className="bg-white px-4 pt-8 pb-56 rounded-lg">
        <div className={'flex justify-between items-center'}>
          <p className="text-base md:text-xl font-bold text-gray-750">Filters</p>
        </div>
        {/* <p className="text-gray-750 text-sm mt-4">Gender</p>
        <div className="flex items-center flex-wrap md:flex-nowrap gap-y-2 mt-2.5">
          {filtersQueryData?.data ? (
            <div className="flex gap-2 mr-4">
              <input
                type="radio"
                name="gender"
                id=""
                className="form-radio text-gray-550 border-2 border-gray-550 rounded-full w-5 h-5"
                value="All"
                onClick={(e) => setGender(e.target.value)}
              />
              <label htmlFor="" className="text-sm text-gray-550">
                All
              </label>
            </div>
          ) : null}
          {filtersQueryData.data ? (
            filtersQueryData.data.gender &&
            filtersQueryData?.data?.gender?.map((gender, id) => {
              return (
                <div className="flex gap-2 mr-4" key={id}>
                  <input
                    type="radio"
                    name="gender"
                    id=""
                    className="form-radio text-gray-550 border-2 border-gray-550 rounded-full w-5 h-5"
                    value={gender}
                    onClick={(e) => setGender(e.target.value)}
                  />
                  <label htmlFor="" className="text-sm text-gray-550">
                    {gender}
                  </label>
                </div>
              );
            })
          ) : (
            <Loader />
          )}
        </div> */}
        <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">Sport</p>
        {filtersQueryData.data ? (
          <SelectList
            selectedValue={sport}
            setSelectedValue={setSport}
            placeholder="Select Sport"
            listData={
              filtersQueryData?.data?.sport
                ? ['All', ...filtersQueryData?.data?.sport.filter((el) => el !== null)]
                : []
            }
          />
        ) : (
          <Loader />
        )}
        <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">Sport Category</p>
        {filtersQueryData.data ? (
          <SelectList
            selectedValue={sportCategory}
            setSelectedValue={setSportCategory}
            placeholder="Select Sport Category"
            listData={
              filtersQueryData?.data?.sport_category
                ? ['All', ...filtersQueryData?.data?.sport_category.filter((el) => el !== null)]
                : []
            }
          />
        ) : (
          <Loader />
        )}

        <p className="text-gray-750 text-sm mt-8">Age Category</p>
        {filtersQueryData.data ? (
          <SelectList
            selectedValue={ageCategory}
            setSelectedValue={setAgeCategory}
            placeholder="Select Age Category"
            listData={
              filtersQueryData?.data?.age_category
                ? ['All', ...filtersQueryData?.data?.age_category.filter((el) => el !== null)]
                : []
            }
          />
        ) : (
          <Loader />
        )}
        {/* <p className="text-gray-750 text-sm shadow-paymentShadow pt-4 mt-4">Weight Category</p>
        {filtersQueryData.data ? (
          <SelectList
            selectedValue={weightCategory}
            setSelectedValue={setWeightCategory}
            placeholder="Select Weight Category"
            listData={
              filtersQueryData?.data?.weight_category
                ? ['All', ...filtersQueryData?.data?.weight_category.filter((el) => el !== null)]
                : []
            }
          />
        ) : (
          <Loader />
        )} */}

        <div className="flex gap-6 items-center justify-end text-base md:text-base mt-2 p-3">
          <button onClick={filterStatusHandler} className="md:hidden flex gap-1 items-center">
            <FontAwesomeIcon icon={faCheck} />
            <p>Apply</p>
          </button>
          <button onClick={resetFilter} className="flex gap-1 items-center">
            <FontAwesomeIcon icon={faTimes} />
            <p>Clear</p>
          </button>
        </div>
      </div>
    </div>
  );
}
